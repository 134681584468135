import { NavigateToOptions } from 'nuxt/dist/app/composables/router';

type IParams = 'freeTrial' | 'freeDownload' | 'unlock' | 'contactUs' | 'serviceConsulting' | 'getFreeEmploymentGuide';
type IArg = {
  targetCountry?: string;
  downloadCountryName?: string;
} & NavigateToOptions;

export default function useLeadsInfo(from: string, type: IParams, arg?: IArg) {
  if (process.client && location.pathname === '/about/free-consultation') {
    return;
  }
  let options = {};
  if (arg) {
    options = {
      external: arg.external,
      replace: arg.replace,
    };
    Reflect.deleteProperty(arg, 'external');
    Reflect.deleteProperty(arg, 'replace');
  }
  navigateTo(
    {
      path: '/about/free-consultation',
      query: {
        path: location.href,
        from,
        type,
        ...(arg as Omit<NavigateToOptions, 'replace' | 'external'>),
      },
    },
    options
  );
}
